import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Checklist = makeShortcode("Checklist");
const CTA = makeShortcode("CTA");
const List = makeShortcode("List");
const Box = makeShortcode("Box");
const Tout = makeShortcode("Tout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`The grants program is not accepting new applications at the moment. Please check back later for the next wave.`}</strong></p>
    </StatusBanner>
    <h1>{`Development Grants`}</h1>
    <h2>{`The MakerDAO Core Community Development Initiative`}</h2>
    <p>{`We provide funding for all kinds of teams that create infrastructure and applications for the Maker ecosystem.`}</p>
    <p>{`So far, since February 2018, we've awarded over 683,887 DAI.`}</p>
    <h2>{`Requirements`}</h2>
    <p>{`The model we use to incubate our grants requires regular check-ins, operational transparency, high developer velocity, active repositories, and frequent communications with MakerDAO and other members of the cohort.`}</p>
    <p>{`How well teams meet these requirements will determine whether they reach their milestones and whether additional funds will be released.`}</p>
    <p>{`This grant is for any teams building on Maker or Dai.`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Self-direct your work`}</p>
      <p>{`Actively commit to regular check-ins`}</p>
      <p>{`Work fast and openly`}</p>
      <p>{`Share code repository activity`}</p>
      <p>{`Communicate with other members of the cohort often`}</p>
      <p>{`Split your work into milestones, we distribute blocks of funds as teams reach predetermined milestones.`}</p>
    </Checklist>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/funding/development-grants/grantee-playbook/"
      }}>{`Grantee Playbook`}</a>{` will provide an idea of what's expected.`}</p>
    <h2>{`Application`}</h2>
    <p>{`Due to the high volume of worthy requests we receive, and the limited resources available, we prioritize applications with conservative budgets.`}</p>
    <CTA mdxType="CTA">
      <h3>{`Apply to `}<a parentName="h3" {...{
          "href": "mailto:grants@makerdao.com"
        }}>{`grants@makerdao.com`}</a></h3>
    </CTA>
    <p>{`To apply, please send an email describing:`}</p>
    <List mdxType="List">
      <Box mdxType="Box">
        <h4>{`What is the Project?`}</h4>
        <p>{`What does the project do, and whom are you helping? Describe the solution and anything else that will help us understand your approach.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Budget`}</h4>
        <p>{`Budget explanations, examples, and anything else that will help a user structure this section of their application.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`What’s the Problem?`}</h4>
        <p>{`The problem you’re solving, explanations, examples, and anything else that will help a user structure this section of their application.`}</p>
      </Box>
    </List>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h4>{`What Happens Next?`}</h4>
        <p>{`Read about what happens once you’ve submitted your application.`}</p>
        <p><a parentName="p" {...{
            "href": ""
          }}>{`Grant Submission`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Life as a Grantee`}</h4>
        <p>{`Learn everything you'll need to be a successful grantee.`}</p>
        <p><a parentName="p" {...{
            "href": "/funding/development-grants/grantee-playbook/"
          }}>{`Grantee Playbook`}</a></p>
      </Box>
    </Tout>
    <h2>{`Past Grant Recipients`}</h2>
    <List mdxType="List">
      <p><a parentName="p" {...{
          "href": "https://dafnir.com/"
        }}><strong parentName="a">{`Dafinir`}</strong></a>{`
Send Dai and the recipient gets local money.`}</p>
      <p><a parentName="p" {...{
          "href": "https://xdai.io/"
        }}><strong parentName="a">{`Burner Wallet`}</strong></a>{`
A mobile phone can send DAI to another address in five seconds with a simple QR code scan.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.pooltogether.com/"
        }}><strong parentName="a">{`Pool Together`}</strong></a>{`
A no-loss, audited savings game powered by blockchain technology.`}</p>
      <p><a parentName="p" {...{
          "href": "https://sablier.finance/"
        }}><strong parentName="a">{`Sablier`}</strong></a>{`
A decentralized app for continuous payments.`}</p>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      